import { useTranslation } from 'react-i18next'

import illustration from '@/assets/images/landing/illustration.webp'
import paths from '@/assets/images/landing/paths.svg'
import star from '@/assets/images/landing/star.svg'

import { ImagePaths, MainTitle, MaxWidthContainer } from '../../Landing.styled'
import { LineListing } from '../LineListing'
import {
  Container,
  EmptyContainer,
  ImageIllustration,
  ImageStar,
  LinesContainerSpecial,
  Main,
  TagLine,
} from './Individualize.styled'

const lines = [
  'perfectForEsl',
  'assignIndividualized',
  'matchCurriculum',
  'createCustom',
  'emphasisSocial',
]

export function Individualize() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <ImageStar src={star} />
        <ImagePaths src={paths} />
        <MainTitle>{t('titles.individualized')}</MainTitle>
        <TagLine>{t('lines.everyChild')}</TagLine>

        <Container>
          <EmptyContainer>
            <ImageIllustration src={illustration} />
          </EmptyContainer>
          <LinesContainerSpecial>
            <LineListing showSignupBtn lines={lines} color="Blue" />
          </LinesContainerSpecial>
        </Container>
      </Main>
    </MaxWidthContainer>
  )
}
