import styled, { css } from 'styled-components'

import flask from '@/assets/images/landing/flask.svg'
import stars from '@/assets/images/landing/stars-yellow.svg'
import { media } from '@/styles/media'

export const Main = styled.div`
  margin: 12.5rem 0;
  border-radius: 4rem;
  width: 100%;
  padding: 12.5rem 0;
  background: url(${flask}) no-repeat, url(${stars}) no-repeat,
    ${({ theme }) => theme.color.babyTooth};
  background-position: 85% 35%, 20% 10%;
  position: relative;

  ${media.tablet(
    () => css`
      margin: 8rem 0;
      padding: 8rem 0;
      background-size: 72px 50px, 70px 70px;
      background-position: 104% 30%, 20% 5%;
      padding-bottom: 16rem;
    `,
  )};
`

export const ImageBook = styled.img`
  position: absolute;
  height: 20rem;
  width: auto;
  bottom: -5rem;
  left: 15%;

  ${media.tablet(
    () => css`
      left: 0;
      height: 16rem;
      bottom: -2rem;
    `,
  )};
`

export const ImageLaptop = styled.img`
  position: absolute;
  height: 25rem;
  width: auto;
  bottom: -7rem;
  right: 10%;

  ${media.tablet(
    () => css`
      display: none;
    `,
  )};
`

export const ImageFunPlay1 = styled.img`
  position: absolute;
  height: 15rem;
  width: auto;
  top: 50%;
  left: 3%;
  z-index: 0;
  border-radius: 10px;

  ${media.tablet(
    () => css`
      height: 12rem;
      top: -10%;
    `,
  )};
`

export const ImageFunPlay2 = styled.img`
  position: absolute;
  height: 15rem;
  width: auto;
  top: 50%;
  right: 3%;
  border-radius: 10px;

  ${media.tablet(
    () => css`
      height: 12rem;
      bottom: -5%;
      top: unset;
    `,
  )};
`

export const ImageLine = styled.img`
  position: absolute;
  right: 40%;
  top: -3rem;

  ${media.tablet(
    () => css`
      height: 3.25rem;
      top: -1.5rem;
    `,
  )};
`
