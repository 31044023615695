import styled, { css } from 'styled-components'

import {
  containerMaxWidth,
  Main as StyledMain,
  MaxWidthContainer as StyledMaxWidthContainer,
} from '@/styles/components'
import { media } from '@/styles/media'

import { Footer } from '../Landing/components/Footer'
import { Nav } from '../Landing/components/Nav'
import { PricingComponent } from './PricingComponent'

export const MaxWidthContainer = styled(StyledMaxWidthContainer)`
  padding: 0 2rem;
  max-width: ${containerMaxWidth};
  margin-top: 2rem;

  ${media.mobile(
    () => css`
      padding: 0 1rem;
    `,
  )}
`

export const Main = styled(StyledMain)`
  padding-top: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.blueChalk};
  justify-content: center;
`

export const SignUpLink = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.big};
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  background: linear-gradient(
    98.27deg,
    ${({ theme }) => theme.color.violetMagican} 1.4%,
    ${({ theme }) => theme.color.kingTriton} 183.31%
  );
  padding: 0.2rem 1rem;
  margin: 0px 0px 16px 16px;
  border-radius: 16px;
  display: inline-block;

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.big};
    `,
  )}
`

export function Pricing() {
  return (
    <Main>
      <Nav />

      <PricingComponent />

      <Footer />
    </Main>
  )
}
