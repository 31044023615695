import { useTranslation } from 'react-i18next'

import heroImg from '@/assets/images/landing/hero-img.webp'
import logo from '@/assets/images/logo-word.png'

import { Button, MaxWidthContainer } from '../../Landing.styled'
import {
  HeroImage,
  HeroImgContainer,
  InnerContainer,
  Logo,
  Main,
  SchoolYourWayTxt,
  SmallTagline,
  Spacer,
  Tagline,
} from './SchoolioYourWay.styled'

export function SchoolYourWay() {
  const { t } = useTranslation('landing')

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    // navigate(route.signUp())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <InnerContainer>
          <SchoolYourWayTxt>{t('titles.schoolYourWay')}</SchoolYourWayTxt>
          <Logo src={logo} />
          {/* <SchoolioDigital>
            {t('titles.familyMembershipPlan')}{' '}
            <sup>
              <TradeMark>&trade;</TradeMark>
            </sup>
          </SchoolioDigital> */}
          <Tagline>{t('titles.forefront')}</Tagline>
          <Tagline>{t('titles.educationShould')}</Tagline>
          <Button bgColor="Orange" marginTop={2} onClick={signUp}>
            {t('buttons.btnJoinUs')}
          </Button>
          <SmallTagline>{t('lines.freeTrialCancelAnytime')}</SmallTagline>
          <Spacer />
          <HeroImgContainer>
            <HeroImage src={heroImg} alt="digital images" />
          </HeroImgContainer>
        </InnerContainer>
      </Main>
    </MaxWidthContainer>
  )
}
