import styled, { css } from 'styled-components'

import heart from '@/assets/images/landing/heart.svg'
import lines from '@/assets/images/landing/lines.svg'
import { containerMaxWidth } from '@/styles/components'
import { media } from '@/styles/media'
import { absoluteCenter, flexColumn, flexRow } from '@/styles/utils'

export const Main = styled.div`
  padding: 12.5rem 0;
  border-radius: 4rem;
  width: 100%;
  position: relative;

  ${media.tablet(
    () => css`
      padding: 8rem 0;
      padding-bottom: 25rem;
    `,
  )};

  background: url(${lines}) no-repeat, url(${heart}) no-repeat,
    ${({ theme }) => theme.color.aliceBlue};
  background-position: left 10rem, 90% 18rem;

  ${media.tablet(
    () => css`
      background-position: left 10rem, 100% 18rem;
    `,
  )};

  ${media.mobile(
    () => css`
      background-position: left 3rem, 102% 18rem;
      background-size: 4.7rem auto, 3.6rem auto;
    `,
  )};
`
export const TagLine = styled.div`
  color: ${({ theme }) => theme.color.greekSea};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.huge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export const Container = styled.div`
  ${flexRow}
  justify-container: flex-end;
  max-width: ${containerMaxWidth};
  margin: auto;

  ${media.tablet(
    () => css`
      ${flexColumn()}
    `,
  )};
`

export const EmptyContainer = styled.div`
  width: 100%;
  position: relative;
`

export const ImageIllustration = styled.img`
  max-height: 50rem;
  width: auto;
  position: absolute;

  ${media.tablet(
    () => css`
      ${absoluteCenter}
      max-height: 27rem;
      top: 70rem;
    `,
  )};
`

export const ImageStar = styled.img`
  max-height: 17rem;
  position: absolute;
  bottom: -2rem;
  right: 0rem;

  ${media.tablet(
    () => css`
      max-height: 13rem;
    `,
  )};
  ${media.tablet(
    () => css`
      max-height: 8rem;
    `,
  )};
`

export const LinesContainerSpecial = styled.div`
  min-width: 22rem;
  max-width: 34rem;
  margin: auto;
`
