import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Loader } from '@/components'
import {
  MsCategory,
  useSubscriptionPlansMscBasedQuery,
} from '@/graphql/generated/types'
import {
  comboBullets,
  coreBullets,
  futureReadinessBullets,
} from '@/pages/parent/dashboard/Home/components/SubscribeCard/MSCSubscriptionCards'
import {
  BestValueTag,
  CrossedOutPrice,
  FeaturesTitle,
  ListItem,
  ListSec,
  PlanPostPriceText,
  PlanPrePriceText,
  PlanPrice,
  PlanTitle,
  SeeLessMoreButton,
  SwitcherDiv,
} from '@/pages/parent/dashboard/Home/components/SubscribeCard/MSCSubscriptionCards/MSCSubscriptionCards.styled'
import {
  LoadingContainer,
  Status,
  StyledStatusLabel,
} from '@/pages/parent/dashboard/Home/components/SubscribeCard/SubscribeCard.styled'
import {
  containerMaxWidth,
  MaxWidthContainer as StyledMaxWidthContainer,
} from '@/styles/components'
import { media } from '@/styles/media'

const MaxWidthContainer = styled(StyledMaxWidthContainer)`
  padding: 0 2rem;
  max-width: ${containerMaxWidth};
  margin-top: 2rem;

  ${media.mobile(
    () => css`
      padding: 0 1rem;
    `,
  )}
`

const SignUpLink = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.big};
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  background: linear-gradient(
    98.27deg,
    ${({ theme }) => theme.color.violetMagican} 1.4%,
    ${({ theme }) => theme.color.kingTriton} 183.31%
  );
  padding: 0.2rem 1rem;
  margin: 0px 0px 16px 16px;
  border-radius: 16px;
  display: inline-block;

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.big};
    `,
  )}
`

const Title = styled.div`
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeight.big};
  font-size: ${({ theme }) => theme.fontSize.gigantic};
`

const PriceDiv = styled.div`
  padding-top: 2rem;
`

const PlansContainer = styled.div`
  display: flex;
  justifycontent: space-around;

  ${media.mobile(
    () => css`
      flex-direction: column;
      gap: 1rem;
    `,
  )}
`

const PlanContainer = styled.div<{ index: string }>`
  border: 1px solid #ccc;
  padding: 0;
  width: 30%;
  margin: ${({ index }) => (index === '1' ? '0px' : '20px')};

  ${media.mobile(
    () => css`
      width: 100%;
      margin: 0;
    `,
  )}
`

const TagLine = styled.p`
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.bigger};
`

export function PricingComponent() {
  const { t } = useTranslation('parent', {
    keyPrefix: 'home',
  })
  const { t: tl } = useTranslation('landing')

  const { data: dataMSCPlans, loading: loadingMSCPlans } =
    useSubscriptionPlansMscBasedQuery()

  const [isAnnual, setIsAnnual] = useState<boolean>(false)
  const [showAll, setShowAll] = useState(true)

  const handleToggle = () => {
    setShowAll(!showAll)
  }

  const extractPrice = (priceString: string) => {
    const match = /\$([\d.]+)/.exec(priceString)
    return match ? parseFloat(match[1]) : 0
  }

  const prioritySequence = [
    MsCategory.Core,
    MsCategory.Combo,
    MsCategory.FutureReadiness,
  ]

  const filteredPlans = dataMSCPlans?.subscriptionPlansMSCBased
    .filter((plan) =>
      isAnnual ? plan.interval === 'Year' : plan.interval === 'Month',
    )
    .sort((a, b) => {
      const aPriority = a.MSCategory
        ? prioritySequence.indexOf(a.MSCategory)
        : prioritySequence.length
      const bPriority = b.MSCategory
        ? prioritySequence.indexOf(b.MSCategory)
        : prioritySequence.length
      return aPriority - bPriority
    })

  let corePlanPrice = 0
  let futureReadinessPlanPrice = 0

  filteredPlans?.forEach((plan) => {
    if (plan.MSCategory === 'Core') corePlanPrice = extractPrice(plan.price)
    if (plan.MSCategory === 'FutureReadiness')
      futureReadinessPlanPrice = extractPrice(plan.price)
  })

  const comboIdealPrice = corePlanPrice + futureReadinessPlanPrice

  return (
    <MaxWidthContainer>
      <Title>{tl('pricing')}</Title>
      <TagLine>{tl('getExactly')}</TagLine>

      <PriceDiv>
        <SwitcherDiv>
          <StyledStatusLabel active={!isAnnual}>
            {`${t('month')} ${t('billing')}`}
          </StyledStatusLabel>
          <Status
            isAnnual={isAnnual}
            onClick={() => {
              setIsAnnual(!isAnnual)
            }}
          />
          <StyledStatusLabel active={isAnnual}>
            {`${t('yearly')} ${t('billing')}`}
          </StyledStatusLabel>
        </SwitcherDiv>

        {!loadingMSCPlans && (
          <PlansContainer>
            {filteredPlans?.slice(0, 3).map((plan, index) => (
              <PlanContainer key={plan._id} index={String(index)}>
                <PlanTitle
                  bgColor={index === 0 ? '0' : index === 1 ? '1' : '2'}
                >
                  {t(`${plan.MSCategory ?? ''}`)}

                  <PlanPrice>
                    {plan.price}
                    {plan.MSCategory === 'Combo' && (
                      <>
                        {' '}
                        <CrossedOutPrice>
                          <del>${comboIdealPrice}</del>
                        </CrossedOutPrice>
                      </>
                    )}
                  </PlanPrice>
                  {plan.MSCategory === 'Combo' && (
                    <BestValueTag>BEST VALUE</BestValueTag>
                  )}
                </PlanTitle>
                <PlanPrePriceText>{plan.prePriceText}</PlanPrePriceText>
                <PlanPostPriceText>{plan.postPriceText}</PlanPostPriceText>

                <SignUpLink href="/sign-up">{tl('signUp')}</SignUpLink>

                {plan.MSCategory === MsCategory.Core && (
                  <ListSec>
                    {showAll && (
                      <>
                        <FeaturesTitle>Features</FeaturesTitle>

                        {coreBullets.map((bullet) => (
                          <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                        ))}
                      </>
                    )}

                    <SeeLessMoreButton onClick={handleToggle}>
                      {showAll ? 'See less...' : 'See more features...'}
                    </SeeLessMoreButton>
                  </ListSec>
                )}

                {plan.MSCategory === MsCategory.FutureReadiness && (
                  <ListSec>
                    {showAll && (
                      <>
                        <FeaturesTitle>Features</FeaturesTitle>

                        {futureReadinessBullets.map((bullet) => (
                          <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                        ))}
                      </>
                    )}

                    <SeeLessMoreButton onClick={handleToggle}>
                      {showAll ? 'See less...' : 'See more features...'}
                    </SeeLessMoreButton>
                  </ListSec>
                )}

                {plan.MSCategory === MsCategory.Combo && (
                  <ListSec>
                    {showAll && (
                      <>
                        <FeaturesTitle>Features</FeaturesTitle>

                        {comboBullets.map((bullet) => (
                          <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                        ))}
                      </>
                    )}

                    <SeeLessMoreButton onClick={handleToggle}>
                      {showAll ? 'See less...' : 'See more features...'}
                    </SeeLessMoreButton>
                  </ListSec>
                )}
              </PlanContainer>
            ))}
          </PlansContainer>
        )}

        {loadingMSCPlans && (
          <LoadingContainer>
            <Loader color="grape" />
          </LoadingContainer>
        )}
      </PriceDiv>
    </MaxWidthContainer>
  )
}
