import { useTranslation } from 'react-i18next'

import book from '@/assets/images/landing/book.webp'
import funPlay1 from '@/assets/images/landing/funPlay1.webp'
import funPlay2 from '@/assets/images/landing/funPlay2.webp'
import laptop from '@/assets/images/landing/laptop.webp'
import line from '@/assets/images/landing/line-green.svg'

import {
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
} from '../../Landing.styled'
import { LineListing } from '../LineListing'
import { TagLineAsTitle } from '../UniqueNeeds/UniqueNeeds.styled'
import {
  ImageBook,
  ImageFunPlay1,
  ImageFunPlay2,
  ImageLaptop,
  ImageLine,
  Main,
} from './Flexible.styled'

const lines = ['printAndGo', 'tabletFriendly', 'addYourOwn', 'switchUpYour']

export function Flexible() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <ImageBook src={book} />
        <ImageLaptop src={laptop} />
        <ImageFunPlay1 src={funPlay1} />
        <ImageFunPlay2 src={funPlay2} />
        <ImageLine src={line} />
        <MainTitle>{t('titles.flexibleToYour')}</MainTitle>
        <TagLineAsTitle>{t('titles.shouldBeEnjoyable')}</TagLineAsTitle>

        <LinesContainer>
          <LineListing showSignupBtn lines={lines} color="Blue" />
        </LinesContainer>
      </Main>
    </MaxWidthContainer>
  )
}
