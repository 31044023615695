import styled, { css } from 'styled-components'

import bgBlur from '@/assets/images/landing/bg-blur.webp'
import dna from '@/assets/images/landing/dna.svg'
import globe from '@/assets/images/landing/globe.svg'
import microScope from '@/assets/images/landing/microscope.svg'
import note from '@/assets/images/landing/note.svg'
import pencil from '@/assets/images/landing/pencil.svg'
import ruler from '@/assets/images/landing/ruler.svg'
import { media } from '@/styles/media'

export const Main = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  width: 100%;
  background: url(${microScope}) no-repeat, url(${globe}) no-repeat,
    url(${dna}) no-repeat, url(${note}) no-repeat, url(${pencil}) no-repeat,
    url(${ruler}) no-repeat, url(${bgBlur}) no-repeat,
    linear-gradient(
      204.44deg,
      ${({ theme }) => theme.color.violetMagican} 23.8%,
      ${({ theme }) => theme.color.kingTriton} 144.62%
    );
  border-radius: 64px;
  position: relative;
  background-position: -2% center, right top, 15% 5%, 75% 30%, 95% 50%, 10% 75%,
    top right, center;

  ${media.tablet(
    () => css`
      background-size: 150px 200px, 150px 160px, auto, auto, auto, auto, auto,
        auto;
    `,
  )}

  ${media.mobile(
    () => css`
      background-size: 105px 160px, 105px 160px, 0, 0, 0, 0, auto, auto;
      background-repeat: no-repeat;
    `,
  )}
`

export const InnerContainer = styled.div`
  max-width: 75rem;
  text-align: center;
  margin: auto;
`

export const SchoolYourWayTxt = styled.h1`
  color: ${({ theme }) => theme.color.white};
  padding-top: 8rem;
  margin-bottom: 0.5rem;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.extraGreat};
  font-weight: ${({ theme }) => theme.fontWeight.light};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.large};
    `,
  )}
`

export const SchoolioDigital = styled.h1`
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.fontWeight.boldest};
  font-size: ${({ theme }) => theme.landingSpecialFontSize.extraGigantic};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.extraMassive};
    `,
  )}
`

export const Tagline = styled.p`
  color: ${({ theme }) => theme.color.white};
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.great};
  font-weight: ${({ theme }) => theme.fontWeight.light};

  ${media.tablet(
    () => css`
      font-size: ${({ theme }) => theme.landingSpecialFontSize.large};
    `,
  )}
`

export const SmallTagline = styled.p`
  color: ${({ theme }) => theme.color.white};
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`

export const Spacer = styled.div`
  height: 40rem;

  ${media.desktop(
    () => css`
      height: 30rem;
    `,
  )}

  ${media.tablet(
    () => css`
      height: 25rem;
    `,
  )}

${media.mobile(
    () => css`
      height: 25rem;
    `,
  )}
`

export const HeroImgContainer = styled.div`
  position: absolute;
  bottom: -200px;
  max-width: 75rem;

  ${media.mobile(
    () => css`
      bottom: -20%;
      left: -10%;
      width: 120%;
    `,
  )}
`

export const HeroImage = styled.img`
  ${media.tablet(
    () => css`
      padding: 0rem 5rem;
    `,
  )}

  ${media.mobile(
    () => css`
      padding: 0rem 0rem;
    `,
  )}
`

export const TradeMark = styled.small`
  font-size: ${({ theme }) => theme.landingSpecialFontSize.bigger};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`

export const Logo = styled.img`
  height: 4rem;
  width: auto;
  margin: auto;

  ${media.tablet(
    () => css`
      height: 2rem;
      width: auto;
    `,
  )}
`
