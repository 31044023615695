import type { UserRole } from '@graphql/generated/types'

import type { CheckTime, MoodType } from '@/pages/child/components/Mood'

export type UserProfile = {
  $name: string
  $email: string
  userName: string
  firstName: string
  lastName: string
  role: string
  grade?: string
}

export enum CommonEvent {
  Login = 'Login',
  LoginFail = 'Login fail',
  Logout = 'Logout',
  LogoutAuto = 'Logout auto',
  LocationWebserviceError = 'Location webservice Error',
}

export enum ChildEvent {
  HomePageVisited = 'Child home page visited',
  MoodCheck = 'Mood check',
  EditProfile = 'Child edit profile',
  SkipMoodCheck = 'Child skip mood check',
  OpenCurrentLesson = 'Child open current lesson',
  OpenOverdueLesson = 'Child open overdue lesson',
  OpenCompletedLesson = 'Child open completed lesson',
  LessonVideoWatched = 'Lesson video watched',
  LessonVideoWatchedComplete = 'Child lesson video watched complete',
  LessonPdfWatched = 'Child lesson pdf watched',
  LessonPdfAnnotated = 'Child lesson pdf annotated',
  LessonPdfSaved = 'Lesson PDF saved',
  CompleteLesson = 'Child complete lesson',
  OpenNotes = 'Child open notes',
  EditNote = 'Child edit note',
  DeleteNote = 'Child delete note',
  AddNote = 'Child add note',
  StartQuiz = 'Child start quiz',
  OpenLessonPage = 'Child lesson page load',
  OpenLabItem = 'Child open lab item',
}

export enum ParentEvent {
  RegistrationStart = 'Registration start',
  ClickedOpenMarketplace = 'Clicked Open Marketplace',
  Registration = 'Registration',
  RegistrationFail = 'Registration fail',
  EmailVerify = 'Email verify',
  HomePageVisited = 'Parent home page visited',
  FreeTrialInit = 'Parent free trial init',
  UpgradeSubscriptionToAnnual = 'Parent upgrade child subscription to annual',
  InteractWithMarketplace = 'Parent interact with marketplace',
  SubscribeChildFail = 'Parent subscribe child fail',
  OpenMarketplace = 'Parent open marketplace',
  OpenFullCalendar = 'Parent open full calendar',
  ScheduleLesson = 'Parent schedule lesson',
  RescheduleLesson = 'Parent reschedule lesson',
  UnscheduleLesson = 'Parent unschedule lesson',
  ScheduleCourse = 'Parent schedule course',
  ScheduleUnit = 'Parent schedule unit',
  RescheduleCourse = 'Parent reschedule course',
  UnscheduleCourse = 'Parent unschedule course',
  RescheduleUnit = 'Parent reschedule unit',
  UnscheduleUnit = 'Parent unschedule unit',
  EditProfile = 'Parent edit profile',
  EditChildProfile = 'Parent edit child profile',
  ChangeChildPassword = 'Parent change child password',
  AddChild = 'Parent add child',
  DeleteChild = 'Parent delete child',
  ViewNonAutoGradedWork = 'Parent view non auto graded work',
  ViewMarketplaceItem = 'Parent view marketplace item',
  AcquireMarketplaceItem = 'Parent acquire marketplace item',
  MarketplacePurchaseSuccess = 'Parent marketplace purchase success',
  MarketplacePurchaseFail = 'Parent marketplace purchase fail',
  CreateCustomCourse = 'Parent create custom course',
  CreateCustomLesson = 'Parent create custom lesson',
  EditLesson = 'Parent edit lesson',
  OpenResources = 'Parent open resources',
  OpenAnalytics = 'Parent open analytics',
  OpenDetailedAnalytics = 'Parent open detailed analytics',
  RegisterBtnClick = 'Parent clicked registration button',
  OpenLessonPage = 'Parent lesson page load',
  LessonVideoWatched = 'Parent lesson video watched',
  LessonVideoWatchedComplete = 'Parent lesson video watched complete',
  DisableAutoPilot = 'Parent disable AutoPilot',
  EnableAutoPilot = 'Parent enable AutoPilot',
  SkippedTutorial = 'Parent skipped tutorial',
  WatchedTutorial = 'Parent watched tutorial',
  adDrivenSiteVisit = 'Ad-Driven Site Visit',
  cancelSubscriptionStarted = 'Parent Cancel Subscription Started',
  cancelSubscriptionFinished = 'Parent Cancel Subscription Finished',
  abandonedCancellationFlow = 'Parent Abandoned Cancellation flow',
  digitalAdOpened = 'Digital Advertisement Opened',
  openCourseInfo = 'Open Course Info',
  OpenLabItem = 'Parent open lab item',
}

export type Event = CommonEvent | ChildEvent | ParentEvent

export type SetProfileProps = {
  firstName: string
  lastName: string
  email: string
  username: string
  role: UserRole
  grade?: string
}

export type ParentProps = {
  _id: string
  firstName: string
  lastName: string
  email: string
}

export type AuthData = {
  email: string
  username: string
}

export type CourseLessonRelatedData = {
  courseId: string
  courseGrade: string
  courseSubject: string
  lessonName: string
}

export type LoginFailEventProps = {
  reason: string
}

export type ParentRegistrationFailEventProps = {
  email?: string
  reason: string
}

export type ParentRegistrationEventProps = {
  distinct_id: string
  email: string
}

export type ChildMoodCheckEventProps = {
  checkTime: CheckTime
  mood: MoodType
  subjectId?: string
  subjectSlug?: string
}

export type CheckSkipMoodCheckProps = {
  checkTime: CheckTime
}

type ParentChildData = {
  childName: string
  childId: string
  childGrade: string
}

type ParentSubjectGradeData = {
  subject: string
  grade: string
}

type ParentCourseData = {
  courseName: string
  courseId: string
  coursePrice?: number
  custom_course_id?: string
  unit_name?: string
}

type ParentLessonData = {
  lessonName: string
  lessonId: string
}

export type OpenCourseInfoProps = {
  courseId: string
  link: string
}

export type CancelSubscriptionData = {
  _id: string
  firstName: string
  lastName: string
  email: string
}

export type CancelAbandonedFlowData = {
  _id: string
  firstName: string
  lastName: string
  email: string
  step: number
  stepName: string
}

type SubscriptionData = {
  currency: string
  amount: number
  interval: string
  trialDays: number
}

export type ParentSubscribeChildFailProps = ParentChildData & SubscriptionData
export type ParentUpgradeSubscriptionToAnnualProps = ParentChildData

export type ParentScheduleCourseProps = ParentChildData &
  ParentSubjectGradeData &
  ParentCourseData

export type ParentScheduleUnitProps = ParentScheduleCourseProps & {
  unitId: string
  unitName: string
}

export type ParentUnscheduleCourseProps = ParentScheduleCourseProps

export type ParentRescheduleCourseProps = ParentScheduleCourseProps

export type ParentRescheduleUnitProps = ParentScheduleUnitProps

export type ParentUnscheduleUnitProps = ParentScheduleUnitProps

export type ParentScheduleLessonProps = ParentChildData &
  ParentSubjectGradeData &
  ParentLessonData

export type ParentRescheduleLessonProps = ParentScheduleLessonProps
export type ParentUnscheduleLessonProps = ParentScheduleLessonProps

export type ParentEditChildProfileProps = ParentChildData
export type ParentChangeChildPasswordProps = ParentChildData
export type ParentAddChildProps = ParentChildData
export type ParentDeleteChildProps = ParentChildData

export type ParentOpenLessonPageProps = ParentLessonData
export type ChildOpenLessonPageProps = ParentLessonData

export type ParentInteractWithMarketplaceProps = {
  action:
    | 'childChanged'
    | 'gradeChanged'
    | 'subjectChanged'
    | 'countryChanged'
    | 'pageChanged'
    | 'itemAddedToCart'
    | 'itemDeletedFromCart'
    | 'clearCart'
}

export type ParentViewMarketplaceItemProps = ParentCourseData &
  ParentSubjectGradeData
export type ParentAcquireMarketplaceItemProps = ParentChildData &
  ParentCourseData &
  ParentSubjectGradeData
export type ParentCreateCustomLessonProps = ParentCourseData &
  ParentSubjectGradeData
export type ParentEditLessonProps = ParentCourseData &
  ParentSubjectGradeData &
  ParentLessonData
export type ParentMarketplacePurchaseSuccessProps = {
  total: number
}
export type ParentMarketplacePurchaseFailProps = {
  total: number
  reason: string
}

export type ParentFreeTrialInitProps = {
  interval: string
  trialDays: number
}

export type AdDrivenSiteVisitProps = {
  adUrl: string
  utmCampaign: string
  utmSource: string
}

export type OpenLabItemProps = {
  itemName: string
}
