import { useTranslation } from 'react-i18next'

import marketplace from '@/assets/images/landing/marketplace.webp'

import {
  ImageTextFlexContainer,
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
} from '../../Landing.styled'
import { LineListing } from '../LineListing'
import {
  ImageContainer,
  ImageDashboard,
  Main,
  TagLine,
} from './Academics.styled'

const lines = [
  'insightsInto',
  'trackGrades',
  'manageMultiple',
  'getPersonalized',
]

export function Academics() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle marginBottom={2.5}>{t('titles.whereAcademics')}</MainTitle>
        <TagLine>{t('titles.weBelieveIn')}</TagLine>
        <ImageTextFlexContainer>
          <LinesContainer>
            <LineListing showSignupBtn lines={lines} color="Purple" />
          </LinesContainer>
          <ImageContainer>
            <ImageDashboard
              src={marketplace}
              loading="lazy"
              alt="analytics dashboard"
            />
          </ImageContainer>
        </ImageTextFlexContainer>
      </Main>
    </MaxWidthContainer>
  )
}
