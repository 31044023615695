import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import type { SingleValue } from 'react-select'
import styled from 'styled-components'

import downloadImg from '@/assets/icons/download-white.svg'
import type { Option } from '@/components'
import { Loader, Select } from '@/components'

import { DateRangePicker } from '../components'
import useCountries from '../hooks/useCountries'
import useDateQuery from '../hooks/useDateQuery'
import {
  Container,
  DownloadButton,
  DownloadIcon,
  FlexRow,
  GridRow,
} from './Location.styled'
import { useCityStateChart } from './useCityStateChart'

const Main = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1.2em;
  width: 100%;
  height: 100vh;
`

export function Location() {
  const { date, setDate } = useDateQuery()
  const { loading, setFilterQuery, filterQuery, graph, data } =
    useCityStateChart(date)
  const countries = useCountries()

  const { t } = useTranslation('admin', { keyPrefix: 'location' })

  function downloadCSV(array: any[], filename: string) {
    const csv = convertArrayOfObjectsToCSV(array)
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function convertArrayOfObjectsToCSV(array: any[]) {
    const header = Object.keys(array[0]).join(',')
    const csv = array.map((obj) => Object.values(obj).join(',')).join('\n')
    return `${header}\n${csv}`
  }

  return (
    <Main>
      <FlexRow>
        <DateRangePicker onChange={setDate} />
      </FlexRow>
      <Container>
        <GridRow>
          <Select
            name="country"
            label={t('filterStates')}
            options={countries.map((country) => ({
              label: country,
              value: country,
            }))}
            defaultValue={{ label: 'Canada', value: 'Canada' }}
            appearance="grey"
            onChange={(e: SingleValue<Option>) => {
              setFilterQuery({ ...filterQuery, country: e?.value ?? '' })
            }}
          />

          <DownloadButton
            thin
            disabled={data?.length === 0 || loading}
            onClick={() => {
              downloadCSV(data ?? [], `users-from-${filterQuery.country}.csv`)
            }}
          >
            <DownloadIcon src={downloadImg} />
            {t('countryUserDownloadbtn', { country: filterQuery.country })}
          </DownloadButton>
        </GridRow>
        {loading ? (
          <Loader color="codeCurrent" size="4em" />
        ) : (
          <Chart
            series={graph.series}
            options={graph}
            height={graph.chart?.height ?? 'auto'}
            type="bar"
          />
        )}
      </Container>
    </Main>
  )
}
