import styled, { css } from 'styled-components'

import { media } from '@/styles/media'

export const Main = styled.div`
  padding: 9.75rem 1rem;
  background-color: #fff1df;
  border-radius: 6rem;

  ${media.tablet(
    () => css`
      padding: 9.75rem 1rem;
      border-radius: 3rem;
    `,
  )};

  ${media.mobile(
    () => css`
      padding: 8rem 1rem;
    `,
  )};
`

export const Header = styled.header`
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-size: 2.5em;
    color: #333;
  }

  .highlight {
    color: #f26d21; /* Orange color */
  }
`

export const FoundersSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 40px;

  ${media.tablet(
    () => css`
      flex-direction: column;
    `,
  )}
`

export const FounderCard = styled.div`
  flex: 1;
  min-width: 300px;

  h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 5px;
  }

  .role {
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
  }

  p {
    font-size: 1em;
    line-height: 1.8;
    color: #555;
  }
`

export const FounderImage = styled.div`
  flex: 1;
  text-align: center;
  margin: auto;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
`

export const BeliefSection = styled.section`
  text-align: center;
  padding: 20px;
  border-radius: 8px;

  h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1em;
    color: #555;
    line-height: 1.8;
  }
`

export const ImageFounders = styled.img`
  box-shadow: 8px 8px 0px #f26d21;
  border-radius: 3rem;
  height: 20rem !important;
  width: auto;
  object-fit: cover;
  margin: auto;

  ${media.tablet(
    () => css`
      height: 20rem;
    `,
  )}

  ${media.mobile(
    () => css`
      height: auto;
      width: 100%;
    `,
  )}
`

export const ButtonContainer = styled.button`
  ${media.mobile(
    () => css`
      padding: 0 1rem;
      width: 100%;
    `,
  )};
`
