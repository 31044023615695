import { useTranslation } from 'react-i18next'

import founders from '@/assets/images/landing/founders.webp'

import { Button, MaxWidthContainer } from '../../Landing.styled'
import {
  BeliefSection,
  ButtonContainer,
  FounderCard,
  FounderImage,
  FoundersSection,
  Header,
  ImageFounders,
  Main,
} from './Founders.styled'

export function Founders() {
  const { t } = useTranslation('landing')

  const signUp = () => {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    // navigate(route.signUp())
    // window.location.reload()
  }

  return (
    <MaxWidthContainer>
      <Main>
        <Header>
          <h1>
            Meet the <span className="highlight">Neurodivergent Founders</span>{' '}
            Behind Schoolio!
          </h1>
        </Header>

        <FoundersSection>
          <FounderCard>
            <h2>Sathish Bala</h2>
            <p className="role">CEO</p>
            <p>{t('lines.sathishLine1')}</p>
            <p>{t('lines.sathishLine2')}</p>
          </FounderCard>

          <FounderImage>
            <ImageFounders
              src={founders}
              loading="lazy"
              alt="Sathish and Lindsey"
            />
          </FounderImage>

          <FounderCard>
            <h2>Lindsey Casselman</h2>
            <p className="role">Head of Learning</p>
            <p>{t('lines.lindseyLine1')}</p>
            <p>{t('lines.lindseyLine2')}</p>
          </FounderCard>
        </FoundersSection>

        <BeliefSection>
          <h2>We Believe...</h2>
          <p>{t('lines.foundersLine1')}</p>
        </BeliefSection>

        <ButtonContainer>
          <Button
            isFullWidthMobile
            marginTop={2}
            bgColor="Orange"
            onClick={signUp}
          >
            {t('buttons.btnGetStarted')}
          </Button>
        </ButtonContainer>
      </Main>
    </MaxWidthContainer>
  )
}
