import { useTranslation } from 'react-i18next'

import calendar from '@/assets/images/landing/calendar.webp'
import trophy from '@/assets/images/landing/trophy.svg'

import {
  ImageTextFlexContainer,
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
} from '../../Landing.styled'
import { LineListing } from '../LineListing'
import {
  ImageContainer,
  ImageDashboard,
  ImageTrophy,
  Main,
  TagLine,
} from './PerfectFit.styled'

const lines = [
  'mixNMatchGrade',
  'scheduleMode',
  'openExpoMode',
  'theLowestStress',
]

export function PerfectFit() {
  const { t } = useTranslation('landing')

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle marginBottom={2.5}>{t('titles.createPerfectFit')}</MainTitle>
        <TagLine>{t('titles.yourChildBest')}</TagLine>
        <ImageTextFlexContainer>
          <ImageTrophy src={trophy} />
          <ImageContainer>
            <ImageDashboard
              src={calendar}
              loading="lazy"
              alt="analytics dashboard"
            />
          </ImageContainer>
          <LinesContainer>
            <LineListing showSignupBtn lines={lines} color="Purple" />
          </LinesContainer>
        </ImageTextFlexContainer>
      </Main>
    </MaxWidthContainer>
  )
}
